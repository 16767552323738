import { applyMiddleware, createStore as createReduxStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import promiseMiddleware from 'redux-promise-middleware';

import rootReducer from './modules/reducer';
import errorMiddleware from './modules/error/middleware';

export default function createStore() {
  const middleware = [thunkMiddleware, errorMiddleware, promiseMiddleware];

  if (process.env.NODE_ENV !== 'production') {
    const { logger } = require('redux-logger');
    middleware.push(logger);
  }

  return createReduxStore(rootReducer, applyMiddleware(...middleware));
}
