import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import AppBarEstacione from '../../../components/AppBarEstacione';
import DefaultTable from '../../../components/DefaultTable/DefaultTable';
import api from '../../../services/api';
import { ACCESS_TOKEN_KEY } from '../../../services/auth';
import './styles.css';

const useStyles = makeStyles(theme => ({
  button: {
    marginTop: theme.spacing(1),
  },
}));

const columns = [
  { id: 'nome', label: 'Nome', minWidth: 100 },
  {
    id: 'cnpj',
    label: 'CNPJ',
    minWidth: 100,
  },
  { id: 'actions', label: 'Ações', padding: 10 },
];
export default function RedeEstacionamentoList() {
  const [data, setData] = useState([]);

  const history = useHistory();

  const classes = useStyles();

  const loadData = async () => {
    const userToken = localStorage.getItem(ACCESS_TOKEN_KEY);
    const headers = { Authorization: `Bearer ${userToken}` };

    const response = await api.get(`/rede-estacionamento`, { headers });

    setData(response.data);
  };

  const deleteData = async id => {
    if (window.confirm('Deseja mesmo excluir?') === false) {
      return;
    }

    const response = await api.delete(`/rede-estacionamento/${id}`);

    if (response.status === 200) {
      setData(data.filter(rede => rede._id !== id));
    }
  };

  const updateData = async id => {
    history.push('/rede-estacionamento/cadastro', {
      id,
    });
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div className="rede-estacionamento-list-container">
      <AppBarEstacione />
      <div className="rede-estacionamento-list-default-list-container">
        <DefaultTable
          columns={columns}
          rows={data}
          deleteData={deleteData}
          updateData={updateData}
        />

        <Button
          color="primary"
          className={classes.button}
          startIcon={<AddIcon />}
          onClick={() => history.push('/rede-estacionamento/cadastro')}>
          Incluir
        </Button>
      </div>
    </div>
  );
}
