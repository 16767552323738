import { ActionType } from 'redux-promise-middleware';

export function createAsyncTypes(actionType) {
  return [
    actionType,
    `${actionType}_${ActionType.Pending}`,
    `${actionType}_${ActionType.Fulfilled}`,
    `${actionType}_${ActionType.Rejected}`,
  ];
}
