import { createMuiTheme } from '@material-ui/core/styles';

const baseTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  palette: {
    primary: {
      light: '#901AE5',
      main: '#5100C2',
      dark: '#b70170',
    },
  },
  shape: {
    borderRadius: 8,
  },
});

const oneTheme = {
  ...baseTheme,
  metrics: {
    contentWidth: 936 + baseTheme.spacing(2),
  },
  overrides: {
    MuiDrawer: {
      paper: {
        backgroundColor: '#18202c',
      },
    },
    MuiButton: {
      contained: {
        boxShadow: 'none',
        '&:active': {
          boxShadow: 'none',
        },
      },
    },
    MuiIconButton: {
      root: {
        padding: baseTheme.spacing(1),
      },
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: 4,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: '#404854',
      },
    },
    MuiListItemText: {
      primary: {
        fontWeight: baseTheme.typography.fontWeightMedium,
      },
    },
    MuiListItemIcon: {
      root: {
        color: 'inherit',
        marginRight: 0,
        '& svg': {
          fontSize: 20,
        },
      },
    },
    MuiAvatar: {
      root: {
        width: 32,
        height: 32,
      },
    },
    MuiToggleButton: {
      root: {
        borderRadius: 5,
      },
      selected: {
        color: 'white !important',
        backgroundColor: `${baseTheme.palette.primary.main} !important`,
      },
    },
    MuiToggleButtonGroup: {
      root: {
        borderRadius: 5,
      },
    },
  },
  props: {
    MuiTab: {
      disableRipple: true,
    },
  },
  mixins: {
    ...baseTheme.mixins,
    toolbar: {
      minHeight: 48,
    },
  },
};

export default oneTheme;
