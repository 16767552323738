import { createAction } from 'redux-actions';
import {
  extractImplicitGrantToken,
  redirectToAuthUrl,
  removeAccessToken,
  setAccessToken,
} from '../../../services/auth';
import { fetchPermissions } from '../user/actions';
import { unsetUnauthorized } from '../error/actions';

export const LOGIN_REQUEST = '@@one/auth/LOGIN_REQUEST';
export const LOGIN_SUCCESS = '@@one/auth/LOGIN_SUCCESS';
export const LOGIN_FAILURE = '@@one/auth/LOGIN_FAILURE';

export const loginRequest = createAction(LOGIN_REQUEST);
export const loginSuccess = createAction(LOGIN_SUCCESS);
export const loginFailure = createAction(LOGIN_FAILURE);

export function afterLogin() {
  return dispatch => {
    dispatch(fetchPermissions());
  };
}

export function login(path, history) {
  return dispatch => {
    const accessToken = extractImplicitGrantToken(window.location.hash);
    if (!accessToken) {
      removeAccessToken();
      redirectToAuthUrl(path);
      dispatch(loginRequest());
    } else {
      setAccessToken(accessToken);
      dispatch(loginSuccess({ accessToken }));
      dispatch(unsetUnauthorized());
      dispatch(afterLogin());
      history.push(path);
    }
  };
}
