import CssBaseline from '@material-ui/core/CssBaseline';
import NoSsr from '@material-ui/core/NoSsr';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import { Provider } from 'react-redux';
import ErrorBoundary from './components/ErrorBoundary';
import './global.css';
import { afterLogin, loginSuccess } from './redux/modules/auth/actions';
import createStore from './redux/store';
import RootRouter from './RootRouter';
import { getAccessToken } from './services/auth';
import theme from './theme/one';

const accessToken = getAccessToken();
const store = createStore();

if (accessToken) {
  store.dispatch(loginSuccess({ accessToken }));
  store.dispatch(afterLogin());
}

function App() {
  return (
    <Provider store={store}>
      <NoSsr>
        <ThemeProvider theme={theme}>
          <SnackbarProvider maxSnack={3}>
            <CssBaseline />
            <ErrorBoundary>
              <RootRouter />
            </ErrorBoundary>
          </SnackbarProvider>
        </ThemeProvider>
      </NoSsr>
    </Provider>
  );
}

export default App;
