import { Button, TextField } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import AppBarEstacione from '../../../components/AppBarEstacione';
import api from '../../../services/api';
import { ACCESS_TOKEN_KEY } from '../../../services/auth';
import './styles.css';

export default function RedeEstacionamentoCadastro() {
  const [cnpj, setCnpj] = useState('');
  const [nome, setNome] = useState('');

  const location = useLocation();
  const id = location?.state?.id;

  const history = useHistory();

  async function handleCreate() {
    const data = {
      nome,
      cnpj,
    };

    const userToken = localStorage.getItem(ACCESS_TOKEN_KEY);
    const headers = { Authorization: `Bearer ${userToken}` };

    try {
      if (id) {
        await api.put(`/rede-estacionamento/${id}`, data, { headers });
      } else {
        await api.post('/rede-estacionamento', data, { headers });
      }

      history.push('/rede-estacionamento');
    } catch (err) {
      alert(`Erro ao ${id ? 'editar' : 'cadastrar'} rede de estacionamento`);
    }
  }

  useEffect(() => {
    if (id) {
      api.get(`/rede-estacionamento/${id}`).then(response => {
        setNome(response.data.nome);
        setCnpj(response.data.cnpj);
      });
    }
  }, [id]);

  return (
    <div className="rede-estacionamento-container">
      <AppBarEstacione />

      <form>
        <TextField
          autoFocus
          label="Nome da rede"
          type="text"
          name="nome"
          value={nome}
          onChange={e => setNome(e.target.value)}
          fullWidth
        />

        <TextField
          autoFocus
          label="CNPJ da rede"
          type="text"
          name="cnpj"
          value={cnpj}
          onChange={e => setCnpj(e.target.value)}
          fullWidth
        />

        <div className="buttons-container">
          <Button
            className="button-create"
            color="primary"
            onClick={handleCreate}>
            Enviar
          </Button>

          <Button
            className="button-goback"
            color="primary"
            startIcon={<ArrowBackIcon />}
            onClick={() => history.push('/rede-estacionamento')}>
            Voltar
          </Button>
        </div>
      </form>
    </div>
  );
}
