import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { isManager, isOne, isGrantedAll } from 'services/permissions';
import { login } from 'redux/modules/auth/actions';
import PageLoading from 'components/PageLoading';
import { logout } from 'services/auth';

export default function PrivateRoute({
  allow,
  render,
  children,
  component: Component,
  ...rest
}) {
  const user = useSelector(state => state.user);
  const auth = useSelector(state => state.auth);
  const error = useSelector(state => state.error);

  const dispatch = useDispatch();

  function renderAuthenticated(props) {
    if (user.loading) {
      return <PageLoading />;
    }

    if (!isManager(user) && !isOne(user)) {
      return logout();
    }

    if (!isGrantedAll(allow, user)) {
      return <Redirect to="/" />;
    }

    if (Component) {
      return <Component {...props} />;
    }

    if (typeof render === 'function') {
      return render(props);
    }
    if (React.isValidElement(children)) {
      return children;
    }
  }

  function renderUnauthenticated(props) {
    if (!auth.loading) {
      dispatch(login(props.match.url, props.history));
    } else {
      return <PageLoading />;
    }
  }

  return (
    <Route
      {...rest}
      render={props => {
        if (error.unauthorized || !auth.isAuthenticated) {
          return renderUnauthenticated(props);
        }
        return renderAuthenticated(props);
      }}
    />
  );
}
