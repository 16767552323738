import { handleActions } from 'redux-actions';
import { LOGIN_REQUEST, LOGIN_SUCCESS } from './actions';

export const initialState = {
  error: null,
  loading: false,
  accessToken: null,
  isAuthenticated: false,
};

export default handleActions(
  {
    [LOGIN_REQUEST]: state => ({
      ...state,
      loading: true,
    }),
    [LOGIN_SUCCESS]: (state, { payload }) => ({
      ...state,
      loading: false,
      accessToken: payload.accessToken,
      isAuthenticated: true,
    }),
  },
  initialState
);
