import isPromise from 'is-promise';
import { setUnauthorized, setNetworkError } from './actions';
import { NetworkError } from '../../../services/NetworkError';
import { HttpError } from '../../../services/HttpError';

export default function middleware() {
  return next => action => {
    if (!isPromise(action.payload)) {
      return next(action);
    }

    return next(action).catch(error => {
      if (error instanceof NetworkError) {
        next(setNetworkError());
      }

      if (error instanceof HttpError && error.statusCode === 401) {
        next(setUnauthorized());
      }
    });
  };
}
