import { createAction } from 'redux-actions';
import apiOne from 'services/apiOne';
import { createAsyncTypes } from '../../createAsyncTypes';

export const [
  FETCH_PERMISSIONS,
  FETCH_PERMISSIONS_PENDING,
  FETCH_PERMISSIONS_FULFILLED,
  FETCH_PERMISSIONS_REJECTED,
] = createAsyncTypes('@@one/user/FETCH_PERMISSIONS');

export const fetchPermissions = createAction(FETCH_PERMISSIONS, () =>
  apiOne.get('/user/permissions')
);
