import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';

const useStyles = makeStyles({
  root: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    color: '#fff',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    background: `linear-gradient(45deg, #5100C2 0%, #901AE5 100%);`,
  },
});

export default function GradientBackground({ children }) {
  const classes = useStyles();
  return <div className={classes.root}>{children}</div>;
}
