import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from '@material-ui/styles/makeStyles';

import logo from 'assets/logo.svg';
import GradientBackground from './GradientBackground';

const useStyles = makeStyles(theme => ({
  logo: {
    width: '30vh',
    marginTop: -100,
    marginBottom: theme.spacing(4),
    animation: 'pulsate 2s ease-in-out infinite',
  },
  '@keyframes pulsate': {
    '0%': {
      opacity: 0.5,
    },
    '50%': {
      opacity: 1,
    },
    '100%': {
      opacity: 0.5,
    },
  },
}));

export default function PageLoading() {
  const classes = useStyles();
  return (
    <GradientBackground>
      <img src={logo} className={classes.logo} alt="One Ticket" />
      <CircularProgress size={80} color="inherit" />
    </GradientBackground>
  );
}
