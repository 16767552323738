import { AppBar, Button, Grid, Toolbar, Typography } from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import makeStyles from '@material-ui/styles/makeStyles';
import React from 'react';
import Link from '@material-ui/core/Link';
import { logout } from '../services/auth';

const lightColor = 'rgba(255, 255, 255, 0.7)';

const useStyles = makeStyles(theme => ({
  button: {
    borderColor: lightColor,
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  links: {
    display: 'flex',
    width: '50%',
    justifyContent: 'space-between',
  },
}));

export default function AppBarEstacione() {
  const classes = useStyles();

  function handleClickLogout() {
    // setSnackbarShown(true);
    logout();
  }

  // @todo adicionar snackbar do logout
  // function handleCloseSnackbar() {
  //   setSnackbarShown(false);
  // }

  return (
    <AppBar position="static">
      <Toolbar>
        <Grid justify="space-between" container spacing={24}>
          <Typography variant="h6" className={classes.title}>
            Veículos
          </Typography>

          <Typography className={classes.links}>
            <Link color="inherit" href="/estacionamento">
              Cadastro Estacionamento
            </Link>
            <Link color="inherit" href="/rede-estacionamento">
              Cadastro Rede Estacionamento
            </Link>

            <Link color="inherit" href="/veiculos">
              Estacionamento
            </Link>

            <Link color="inherit" href="/relatoriototal">
              Relatório Financeiro
            </Link>
          </Typography>

          <Button
            size="small"
            color="inherit"
            className={classes.button}
            onClick={handleClickLogout}
          >
            <ExitToAppIcon className={classes.icon} fontSize="small" />
            Logout
          </Button>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}
