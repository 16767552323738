import { Button, TextField } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import AppBarEstacione from '../../../components/AppBarEstacione';
import RedeEstacionamentoSelect from '../../../components/RedeEstacionamentoSelect';
import api from '../../../services/api';
import { ACCESS_TOKEN_KEY } from '../../../services/auth';
import './styles.css';

export default function EstacionamentoCadastro() {
  const [nome, setNome] = useState('');
  const [redeEstacionamentoId, setRedeEstacionamentoId] = useState('');
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');

  const history = useHistory();

  const location = useLocation();
  const id = location?.state?.id;

  async function handleCreate() {
    const data = {
      nome,
      longitude,
      latitude,
      redeEstacionamentoId,
    };

    try {
      const userToken = localStorage.getItem(ACCESS_TOKEN_KEY);
      const headers = { Authorization: `Bearer ${userToken}` };

      if (id) {
        await api.put(`/estacionamento/${id}`, data, { headers });
      } else {
        await api.post('/estacionamento', data, { headers });
      }

      history.push('/estacionamento');
    } catch (err) {
      alert(`Erro ao ${id ? 'editar' : 'cadastrar'} estacionamento`);
    }
  }

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(position => {
      if (!id) {
        const { latitude, longitude } = position.coords;

        setLatitude(latitude);
        setLongitude(longitude);
      }
    });
  }, [id]);

  useEffect(() => {
    if (id) {
      api.get(`/estacionamento/${id}`).then(response => {
        setNome(response.data.nome);
        setRedeEstacionamentoId(response.data.redeEstacionamentoId);
        setLongitude(response.data.localizacao.coordinates[0]);
        setLatitude(response.data.localizacao.coordinates[1]);
      });
    }
  }, [id]);

  return (
    <div className="estacionamento-container">
      <AppBarEstacione />

      <form>
        <TextField
          autoFocus
          label="Nome do estacionamento"
          type="text"
          name="nome"
          value={nome}
          onChange={e => setNome(e.target.value)}
          fullWidth
        />

        <TextField
          autoFocus
          label="Latitude do estacionamento"
          type="text"
          name="latitude"
          value={latitude}
          onChange={e => setLatitude(e.target.value)}
          fullWidth
        />

        <TextField
          autoFocus
          label="Longitude do estacionamento"
          type="text"
          name="longitude"
          value={longitude}
          onChange={e => setLongitude(e.target.value)}
          fullWidth
        />

        <RedeEstacionamentoSelect
          value={redeEstacionamentoId}
          setRedeEstacionamento={setRedeEstacionamentoId}
        />

        <div className="buttons-container">
          <Button
            className="button-create"
            color="primary"
            onClick={handleCreate}>
            Enviar
          </Button>

          <Button
            className="button-goback"
            color="primary"
            startIcon={<ArrowBackIcon />}
            onClick={() => history.push('/estacionamento')}>
            Voltar
          </Button>
        </div>
      </form>
    </div>
  );
}
