import React from 'react';
import { connect } from 'react-redux';

import Box from '@material-ui/core/Box';
import Modal from './Modal';
import GradientBackground from './GradientBackground';

// Use class component exclusively because
// of `componentDidCatch` and `getDerivedStateFromError`.
export class ErrorBoundary extends React.Component {
  state = {
    hasRenderingError: false,
  };

  static getDerivedStateFromError() {
    return { hasRenderingError: true };
  }

  render() {
    const { network } = this.props.errors;
    const { hasRenderingError } = this.state;

    const hasError = network || hasRenderingError;

    if (!hasError) {
      return this.props.children;
    }

    return (
      <>
        <GradientBackground />
        <Modal open={hasError} maxWidth="sm">
          <Modal.Title>Oops! Ocorreu um erro...</Modal.Title>
          <Modal.Content>
            <Box px={2} pb={2} fontSize={16}>
              {network
                ? 'Verifique sua conexão com a internet e tente novamente.'
                : 'Por favor, tente novamente.'}{' '}
              Caso o erro persista, entre em contato com a One através do e-mail{' '}
              <Box
                color="primary.main"
                target="_blank"
                component="a"
                href="mailto:atendimento@estaci.one">
                atendimento@estaci.one
              </Box>
              .
            </Box>
          </Modal.Content>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = state => ({
  errors: state.error,
});

export default connect(mapStateToProps)(ErrorBoundary);
