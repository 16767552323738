import { getAccessToken } from './auth';
import { HttpError } from './HttpError';
import { NetworkError } from './NetworkError';

const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
const API_BASE_URL = process.env.REACT_APP_ONE_API_URL;

function parseData(response) {
  const contentType = response.headers.get('Content-Type');

  if (contentType === 'application/json') {
    return response.json();
  }

  return response.text();
}

async function handleResponse(response) {
  const data = await parseData(response);
  if (response.ok) {
    return data;
  }
  throw new HttpError(response.statusText, response.status, data);
}

function handleError(error) {
  if (error instanceof HttpError) {
    throw error;
  }

  throw new NetworkError(error.message);
}

export function createHeaders(headers = {}) {
  return {
    ...headers,
    'Content-Type': 'application/json',
    'X-Requested-With': 'XmlHttpRequest',
    Authorization: `Bearer ${getAccessToken()}`,
  };
}

export function createConfig(method, config = {}) {
  return {
    method,
    ...config,
    headers: createHeaders(config.headers),
  };
}

export function withClientId(baseUrl) {
  const url = new URL(baseUrl);
  if (!url.search) {
    return `${url}?client_id=${CLIENT_ID}`;
  }
  return `${url}&client_id=${CLIENT_ID}`;
}

export function createRequest(path, method = 'GET', options) {
  return fetch(
    withClientId(`${API_BASE_URL}${path}`),
    createConfig(method, options)
  )
    .then(handleResponse)
    .catch(handleError);
}

export const apiOne = {
  get(url, options) {
    return createRequest(url, 'GET', options);
  },
  put(url, body, options) {
    return createRequest(url, 'PUT', { body, ...options });
  },
  post(url, body, options) {
    return createRequest(url, 'POST', { body, ...options });
  },
  delete(url, options) {
    return createRequest(url, 'DELETE', options);
  },
};

export default apiOne;
