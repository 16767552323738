import React, { useState } from 'react';
import { AppBar, Button, Grid, Toolbar, Typography } from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import './styles.css';
import { useHistory } from 'react-router-dom';
import makeStyles from '@material-ui/styles/makeStyles';
import { logout } from '../../services/auth';
import api from '../../services/api';
import AppBarEstacione from '../../components/AppBarEstacione';

const lightColor = 'rgba(255, 255, 255, 0.7)';

const useStyles = makeStyles(theme => ({
  button: {
    borderColor: lightColor,
  },
  icon: {
    marginRight: theme.spacing(1),
  },
}));

export default function RelatorioTotal() {
  const [listaCarros, setListaCarros] = useState([]);
  const history = useHistory();
  const [totalFaturado, setTotalFaturado] = useState(0);
  const setError = useState()[1];
  // datas
  const [dataInicial, setDataInicial] = useState();
  const [dataFinal, setDataFinal] = useState();

  const classes = useStyles();

  function handleClickLogout() {
    logout();
  }
  function imprimirRelatorio() {
    try {
      window.print();
      localStorage.clear();
      history.push('/');
    } catch (err) {
      setError(err);
    }
  }

  async function buscaPorData() {
    const data = {
      dataInicio: dataInicial,
      dataFim: dataFinal,
    };

    async function feychDataB() {
      try {
        const response = await api.post('planilha', data);
        const { atualizado, valorTotal } = response.data;
        setTotalFaturado(valorTotal);
        setListaCarros(atualizado);
      } catch (err) {
        setError(err);
      }
    }
    feychDataB();
  }

  if (listaCarros.length > 0) {
    return (
      <>
        {/* ///preciso fazer os filtros para aparecerem na tela e um botao para fazer a consulta
        //e depois mostrar isso na tela, a tabela só precisa aparecer depois da consulta realizada
        //antes não é preciso, mostrar só os filtros. */}
        <AppBarEstacione />

        <div>
          <p>
            Data Inicial
            <input
              name="dataInicial"
              type="date"
              onChange={e => setDataInicial(e.target.value)}
            />
            Data Final{' '}
            <input
              name="dataFinal"
              type="date"
              onChange={e => setDataFinal(e.target.value)}
            />
            <Button
              size="small"
              color="inherit"
              className={classes.button}
              onClick={buscaPorData}>
              Buscar
            </Button>
          </p>
        </div>

        <div>
          <ul>
            <li>
              <strong>quantidade de veiculos: </strong>
              <p>{listaCarros.length}</p>
            </li>

            <table>
              <thead className="cabecalho">
                <tr>
                  <th>Placa</th>
                  <th>Forma de pagamento</th>
                  <th>Data de entrada</th>
                  <th>Horário de entrada</th>
                  <th>Data de saída</th>
                  <th>Horário de saída</th>
                  <th>Tempo permanecido</th>
                  <th>Dinheiro pago</th>
                </tr>
              </thead>
              <tbody>
                {listaCarros.map(veiculo => (
                  <tr key={veiculo.id}>
                    <td>{veiculo.placa}</td>
                    <td>{veiculo.formaQuePagou}</td>
                    <td>{veiculo.entradaD}</td>
                    <td>{veiculo.entradaH}</td>
                    <td>{veiculo.saidaD}</td>
                    <td>{veiculo.saidaH}</td>
                    <td>{veiculo.horaCalculada}</td>
                    <td>
                      {veiculo.valorPago.toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      })}
                    </td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <th>
                    Total recebido:
                    {totalFaturado.toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </th>
                </tr>
              </tfoot>
            </table>
          </ul>
        </div>
        <Button variant="contained" onClick={imprimirRelatorio}>
          Imprimir
        </Button>
      </>
    );
  }
  return (
    <>
      <AppBarEstacione />

      <div>
        <p>
          Data Inicial{' '}
          <input
            name="dataInicial"
            type="date"
            onChange={e => setDataInicial(e.target.value)}
          />
          Data Final{' '}
          <input
            name="dataFinal"
            type="date"
            onChange={e => setDataFinal(e.target.value)}
          />
          <Button
            size="small"
            color="inherit"
            className={classes.button}
            onClick={buscaPorData}>
            Buscar
          </Button>
        </p>
      </div>
    </>
  );
}
