import TableBody from '@material-ui/core/TableBody';
import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

export default function DefaultTableBody({
  rows,
  columns,
  updateData,
  deleteData,
}) {
  const editAndDeleteIcons = id => (
    <>
      <IconButton onClick={async () => deleteData(id)}>
        <DeleteIcon color="secondary" />
      </IconButton>
      <IconButton onClick={async () => updateData(id)}>
        <EditIcon color="primary" />
      </IconButton>
    </>
  );

  // const getValue = () => {
  //   column.id === 'actions'
  //     ? editAndDeleteIcons(row._id)
  //     : if column.format
  //     ? column.format(value)
  //     : value;
  // };

  const getValue = (column, row, value) => {
    if (column.id === 'actions') {
      return editAndDeleteIcons(row._id);
    }

    if (column.format) {
      return column.format(value);
    }

    return value;
  };

  return (
    <TableBody>
      {rows.map(row => (
        <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>
          {columns.map(column => {
            const value = row[column.id];

            return (
              <TableCell
                key={column.id}
                align={column.align}
                type={column.type}>
                {getValue(column, row, value)}
              </TableCell>
            );
          })}
        </TableRow>
      ))}
    </TableBody>
  );
}
