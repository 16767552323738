import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/styles/makeStyles';
import React from 'react';
import { useHistory } from 'react-router-dom';
import GradientBackground from '../../components/GradientBackground';
import './styles.css';

const lightColor = 'rgba(255, 255, 255, 0.7)';

const useStyles = makeStyles(theme => ({
  button: {
    borderColor: lightColor,
  },
  icon: {
    marginRight: theme.spacing(1),
  },
}));

export default function Logout() {
  const classes = useStyles();
  const history = useHistory();

  function handleEntrarClick() {
    history.push('/login');
  }

  return (
    <GradientBackground>
      <Grid
        style={{ height: '100%' }}
        container
        spacing={16}
        direction="column"
        alignItems="center"
        justify="center">
        <Card elevation={3} style={{ maxWidth: 456 }}>
          <CardContent>
            <Typography variant="h5" className={classes.title}>
              Você está fora do sistema.
            </Typography>
            <p>Deseja entrar novamente?</p>
          </CardContent>
          <CardActions>
            <Button color="primary" onClick={() => handleEntrarClick()}>
              Entrar
            </Button>
          </CardActions>
        </Card>
      </Grid>
    </GradientBackground>
  );
}
