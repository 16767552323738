import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';
import { useHistory } from 'react-router-dom';
import './styles.css';

export default function Login() {
  const history = useHistory();

  async function handleLogin() {
    history.push('/veiculos');
  }

  handleLogin();

  return (
    <div>
      <CircularProgress size={80} color="inherit" />
    </div>
  );
}
