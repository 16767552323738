export const ONE = 'one';
export const ESTACIONE_MANAGER = 'estacione_manager';
export const FINANCEIRO = 'financeiro';

export const permissions = {
  ONE,
  ESTACIONE_MANAGER,
  FINANCEIRO,
};

export function isGranted(permission, user) {
  return user.permissions.indexOf(permission) > -1;
}

export function isGrantedAll(perms = [], user) {
  return perms.every(perm => isGranted(perm, user));
}

export function isGrantedAny(perms = [], user) {
  return perms.some(perm => isGranted(perm, user));
}

export function isManager(user) {
  return isGranted(ESTACIONE_MANAGER, user);
}

export function isOne(user) {
  return isGranted(ONE, user);
}

export function isFinanceiro(user) {
  return isGranted(FINANCEIRO, user);
}
