import * as qs from 'querystring';

export const ACCESS_TOKEN_KEY = 'access_token';
export const REFRESH_TOKEN_KEY = 'refresh_token';

const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
const SERVER_ONE_URL = process.env.REACT_APP_ONE_WEBSITE_URL;

export function getAccessToken() {
  const token = localStorage.getItem(ACCESS_TOKEN_KEY);
  return token ? JSON.parse(token) : null;
}

export function extractImplicitGrantToken(hash) {
  const query = qs.parse(hash.replace('#', ''));
  return query.access_token ? query.access_token : null;
}

export function setAccessToken(token) {
  localStorage.setItem(ACCESS_TOKEN_KEY, JSON.stringify(token.trim()));
}

export function getAuthUrl(path) {
  const { origin } = window.location;
  return `${SERVER_ONE_URL}/oauth/authorize?response_type=token&client_id=${CLIENT_ID}&redirect_uri=${origin}${path}`;
}

export function redirectToAuthUrl(path) {
  window.location.replace(getAuthUrl(path));
}

export function removeAccessToken() {
  localStorage.removeItem(ACCESS_TOKEN_KEY);
}

export function logout() {
  removeAccessToken();
  window.location.href = '/logout';
}
