import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import withStyles from '@material-ui/styles/withStyles';
import CloseIcon from '@material-ui/icons/Close';

export function Modal({ children, ...rest }) {
  return <Dialog {...rest}>{children}</Dialog>;
}

const titleStyles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    top: theme.spacing(1),
    right: theme.spacing(1),
    color: theme.palette.grey[500],
    position: 'absolute',
  },
});

const contentStyles = () => ({
  root: {
    margin: 0,
    padding: 0,
  },
});

const actionsStyles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
});

export function Title({ classes, children, onClose }) {
  return (
    <DialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose && (
        <IconButton className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      )}
    </DialogTitle>
  );
}

Modal.Title = withStyles(titleStyles)(Title);
Modal.Content = withStyles(contentStyles)(DialogContent);
Modal.Actions = withStyles(actionsStyles)(DialogActions);

export default Modal;
