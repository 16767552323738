import React, { useState, useEffect } from 'react';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import { ACCESS_TOKEN_KEY } from '../services/auth';

import api from '../services/api';

const useStyles = makeStyles(() => ({
  formControl: {
    minWidth: '25ch',
  },
}));

export default function RedeEstacionamentoSelect({
  value,
  setRedeEstacionamento,
  createEmptyField = false,
}) {
  const [redesDeEstacionamento, setRedesDeEstacionamento] = useState([]);

  const classes = useStyles();

  const userToken = localStorage.getItem(ACCESS_TOKEN_KEY);
  const headers = { Authorization: `Bearer ${userToken}` };

  useEffect(() => {
    api.get(`/rede-estacionamento`, { headers }).then(response => {
      setRedesDeEstacionamento(response.data);
    });
  }, [userToken]);

  return (
    <FormControl className={classes.formControl}>
      <InputLabel>Rede de estacionamento</InputLabel>
      <Select
        value={value || ''}
        onChange={event => setRedeEstacionamento(event.target.value)}>
        {createEmptyField === true ? (
          <MenuItem value="">Nenhum</MenuItem>
        ) : undefined}
        {redesDeEstacionamento.map(rede => (
          <MenuItem key={rede._id} value={rede._id}>
            {rede.cnpj} -{rede.nome}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
