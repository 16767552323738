import { createAction } from 'redux-actions';

export const unsetAll = createAction('@@one/error/UNSET_ALL');

export const setUnauthorized = createAction('@@one/error/SET_UNAUTHORIZED');
export const unsetUnauthorized = createAction('@@one/error/UNSET_UNAUTHORIZED');

export const setNetworkError = createAction('@@one/error/SET_NETWORK_ERROR');
export const unsetNetworkError = createAction(
  '@@one/error/UNSET_NETWORK_ERROR'
);
