import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';

import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';

import DefaultTableHeader from './DefaultTableHeader';
import DefaultTableBody from './DefaultTableBody';

const useStyles = makeStyles({
  table: {
    maxWidth: '80vw',
    margin: 'auto',
  },
  root: {
    width: '100%',
  },
});

export default function DefaultTable({
  columns,
  rows,
  deleteData,
  updateData,
}) {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <DefaultTableHeader columns={columns} />
          <DefaultTableBody
            rows={rows}
            columns={columns}
            updateData={updateData}
            deleteData={deleteData}
          />
        </Table>
      </TableContainer>
    </Paper>
  );
}
