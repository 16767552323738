import { handleActions } from 'redux-actions';
import {
  unsetAll,
  setUnauthorized,
  unsetUnauthorized,
  setNetworkError,
  unsetNetworkError,
} from './actions';

export const initialState = {
  network: false,
  unauthorized: false,
};

export default handleActions(
  {
    [unsetAll]: state => ({
      ...state,
      network: false,
      unauthorized: false,
    }),
    [setUnauthorized]: state => ({
      ...state,
      unauthorized: true,
    }),
    [unsetUnauthorized]: state => ({
      ...state,
      unauthorized: false,
    }),
    [setNetworkError]: state => ({
      ...state,
      network: true,
    }),
    [unsetNetworkError]: state => ({
      ...state,
      network: false,
    }),
  },
  initialState
);
