import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import DirectionsCar from '@material-ui/icons/DirectionsCar';
import makeStyles from '@material-ui/styles/makeStyles';
import { BrowserQRCodeSvgWriter } from '@zxing/library';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import AppBarEstacione from '../../components/AppBarEstacione';
import api from '../../services/api';
import './styles.css';
import { ACCESS_TOKEN_KEY } from '../../services/auth';

const lightColor = 'rgba(255, 255, 255, 0.7)';

const useStyles = makeStyles(theme => ({
  button: {
    borderColor: lightColor,
  },
  icon: {
    marginRight: theme.spacing(1),
  },
}));

export default function Veiculos() {
  // modal Abertura de caixa
  const [abrirCaixa, setAbrirCaixa] = useState(false);
  const [dinheiro, setDinheiro] = useState(0);
  // modal inseri placa
  const [entradaVeiculo, setEntradaVeiculo] = useState(false);
  const [placa, setPlaca] = useState('');

  const [imprimeComprovante, setImprimeComprovante] = useState(false);
  const [codigoComprovante, setCodigoComprovante] = useState('0');

  const [saidaVeiculo, setSaidaVeiculo] = useState(false);
  const [comprovanteVeiculo, setComprovanteVeiculo] = useState('');

  const [pagamentoVeiculo, setPagamentoVeiculo] = useState(false);
  const [dinheiroHaPagar, setDinheiroPagar] = useState(0);
  const [dinheiroPago, setDinheiroPago] = useState(0);

  const [caixaAberto, setCaixaAberto] = useState(false);
  const [formaPagamento, setFormaPagamento] = useState();
  const [recebeuPagamento, setRecebeuPagamento] = useState();

  const [veiculos, setVeiculos] = useState([]);
  const [dinheiroEmCaixa, setDinheiroEmCaixa] = useState(0);
  const [dinheiroAoAbrir, setDinheiroAoAbrir] = useState(0);

  const history = useHistory();
  const classes = useStyles();
  const codeWriter = new BrowserQRCodeSvgWriter();

  // pensar para colocar as informações da pagina principal aqui nesta busca
  async function buscaVeiculos() {
    api.get('/veiculo').then(response => {
      const { listaNova, dinheiroEmCaixa, dinheiroAoAbrir } = response.data;

      setVeiculos(listaNova);
      setDinheiroEmCaixa(dinheiroEmCaixa);
      setDinheiroAoAbrir(dinheiroAoAbrir);
    });
  }

  function verificaCaixaAberto(response) {
    setCaixaAberto(response);

    if (response !== null && response) {
      buscaVeiculos();
    } else {
      // mostra modal para fazer abertura do caixa
      setAbrirCaixa(true);
    }
  }

  async function handleClose() {
    // preciso colocar o usuarioId, valor que o usuario inseriu
    try {
      // pensar em levar o id do caixa caso tiver
      const data = {
        dinheiroEmCaixa: dinheiro,
        eventoId: 0,
      };
      const userToken = localStorage.getItem(ACCESS_TOKEN_KEY);
      const headers = { Authorization: `Bearer ${userToken}` };

      const retorno = await api.post('caixa', data, { headers });

      if (retorno.status === 200) {
        // quando for fechado o modal envia para api o valor da entrada e ir para
        // a página principal
        buscaVeiculos();
        setAbrirCaixa(false);
      } else {
        setAbrirCaixa(false);
        alert(
          'Problemas ao abrir o caixa, favor verificar informações inseridas.'
        );
      }
    } catch (err) {
      setAbrirCaixa(false);
      alert('Erro ao abrir o caixa!');
    }
  }

  function chamaImpressora() {
    window.print();
    setImprimeComprovante(false);
    buscaVeiculos();
  }

  async function consultarValor() {
    try {
      const data = {
        comprovante: comprovanteVeiculo,
      };

      const response = await api.post('/consulta', data);

      if (response.status === 200) {
        setSaidaVeiculo(false);
        // abrir novo modal para finalizar o pagamento
        // estas informações precisamos para finalizar o pagamento comprovante, usuarioIdPagamento, formaPagamento, valor
        setDinheiroPagar(response.data.valor);
        setComprovanteVeiculo(response.data.identificaVeiculo);
        setPagamentoVeiculo(true);
      }
    } catch (err) {
      alert('Houve um erro ao buscar o valor do pagamento');
    }
  }

  function naoRecebeuPagamento() {
    const justificativa = document.getElementById('declarar');
    const causas = document.getElementById('causa');

    justificativa.hidden = false;
    causas.hidden = false;

    setRecebeuPagamento(false);
  }

  async function finalizarPagamento() {
    try {
      const justificativa = document.getElementById('declarar').value;

      if (!recebeuPagamento && justificativa.length === 0) {
        return alert(
          'Você não colocou a justificativa do por que não recebeu o dinheiro.'
        );
      }

      if (recebeuPagamento == null || formaPagamento == null) {
        return alert(
          'Você não colocou se recebeu o dinheiro ou a forma de pagamento.'
        );
      }

      if (Number(dinheiroHaPagar) !== Number(dinheiroPago)) {
        return alert(
          'O dinheiro recebido é menor do que o valor que há para pagar.'
        );
      }

      const data = {
        comprovante: comprovanteVeiculo,
        formaPagamento,
        valor: dinheiroHaPagar,
        recebeu: recebeuPagamento,
        justificativa,
      };

      const userToken = localStorage.getItem(ACCESS_TOKEN_KEY);
      const headers = { Authorization: `Bearer ${userToken}` };

      const response = await api.put('/veiculo', data, { headers });

      if (response.status === 200) {
        setPagamentoVeiculo(false);
        setRecebeuPagamento();
        setFormaPagamento();
        setDinheiroPago(0);
        setComprovanteVeiculo('');
        buscaVeiculos();
      } else {
        alert('Ocorreu um erro ao finalizar saída');
      }
    } catch (err) {
      alert('Houve erro ao registrar o pagamento');
    }
  }

  async function adicionaVeiculo() {
    if (!placa) {
      setEntradaVeiculo(false);
      return;
    }

    try {
      const data = { placa };

      const userToken = localStorage.getItem(ACCESS_TOKEN_KEY);
      const headers = { Authorization: `Bearer ${userToken}` };

      const response = await api.post('veiculo', data, { headers });

      if (response.status === 200) {
        setEntradaVeiculo(false);
        setCodigoComprovante(response.data.identificaVeiculo);
        setImprimeComprovante(true);
        setPlaca('');
        codeWriter.writeToDom('#barcode', codigoComprovante, 300, 300);
      } else {
        alert('Problemas ao inserir veiculo, Tente Novamente!!!');
      }
    } catch (err) {
      alert('Problemas ao conectar com a API.');
    }
  }

  function vaiParaRelatorio() {
    // fazer a busca do tipo de usuario para ir pro relatorio total
    history.push('/relatorio');
  }

  // Vamos exibir se:
  // - está aberto ou não,
  // - quantas vagas possui,
  // - quantos carros há no estacionamento,
  // - dinheiro em caixa,
  // - dinheiro sendo gerado pelos carros nas vagas, etc.
  // Porém, por enquanto, pode exibir só o que tu tiver de dados estáticos em algum lugar pra garantir a criação da funcionalidade.
  useEffect(() => {
    api.get('caixa').then(response => {
      verificaCaixaAberto(response.data);
    });
    // eslint-disable-next-line
  }, []);

  return (
    <div className="veiculo-container">
      <AppBarEstacione />

      <header>
        <table>
          <thead className="cabecalho">
            <tr>
              <th>estacionamento</th>
              <th>Total de vagas</th>
              <th>Veiculos Estacionados</th>
              <th>Dinheiro em caixa</th>
              <th>Gerado hoje</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{caixaAberto ? 'Aberto' : 'Fechado'}</td>
              <td>{50 - veiculos.length}</td>
              <td>{veiculos.length}</td>
              <td>
                {dinheiroEmCaixa.toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </td>
              <td>
                {(dinheiroEmCaixa - dinheiroAoAbrir).toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </td>
            </tr>
          </tbody>
        </table>
      </header>

      <Paper elevation={3} style={{ padding: 16, margin: 16 }}>
        <Grid justify="space-between" container spacing={24}>
          <Typography variant="h6" className={classes.title}>
            Veículos Estacionados
          </Typography>

          <div>
            <Button onClick={e => vaiParaRelatorio()} color="primary">
              Fechar Caixa
            </Button>
            <Button
              onClick={e => setEntradaVeiculo(true)}
              variant="outlined"
              color="primary">
              Inserir Placa
            </Button>
            <Button
              onClick={e => setSaidaVeiculo(true)}
              variant="outlined"
              color="primary">
              Saída Veículo
            </Button>
          </div>
        </Grid>

        <Divider />

        <List dense={false}>
          {veiculos.reverse().map(veiculo => (
            <div key={veiculo.id}>
              <ListItem>
                <ListItemIcon>
                  <DirectionsCar />
                </ListItemIcon>
                <ListItemText
                  primary={veiculo.placa}
                  secondary={veiculo.hora}
                />
              </ListItem>
              <Divider />
            </div>
          ))}
        </List>
      </Paper>

      <Dialog
        open={abrirCaixa}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-descriprion">
        <DialogTitle>Abertura do caixa</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Valor em caixa"
            type="number"
            name="valorEmCaixa"
            fullWidth
            value={dinheiro}
            onChange={e => setDinheiro(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleClose}>
            Enviar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Modal para adicionar a placa do veículo que está entrando */}
      <Dialog
        open={entradaVeiculo}
        onClose={adicionaVeiculo}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-descriprion">
        <DialogTitle>Entrada de veículo</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Placa do veículo"
            type="text"
            name="placaVeiculo"
            fullWidth
            value={placa}
            onChange={e => setPlaca(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEntradaVeiculo(false)}>Cancelar</Button>
          <Button color="primary" onClick={() => adicionaVeiculo(placa)}>
            Adicionar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Modal para imprimir comprovante de estacionamento */}
      <Dialog open={imprimeComprovante} onClose={chamaImpressora}>
        <DialogTitle>Comprovante Estaci.one</DialogTitle>
        <DialogContent style={{ textAlign: 'center' }}>
          <pre>
            <code id="barcode" />
          </pre>
          <h1>{codigoComprovante}</h1>
          <p>(código para conferência na saída)</p>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={chamaImpressora}>
            Imprimir
          </Button>
        </DialogActions>
      </Dialog>

      {/* Modal para saida do veículo */}
      <Dialog open={saidaVeiculo} onClose={consultarValor}>
        <DialogTitle>Saída de veículo</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            label="Código do comprovante"
            type="text"
            name="comprovante"
            fullWidth
            value={comprovanteVeiculo}
            onChange={e => setComprovanteVeiculo(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSaidaVeiculo(false)}>Cancelar</Button>
          <Button color="primary" onClick={consultarValor}>
            Saída
          </Button>
        </DialogActions>
      </Dialog>

      {/* Modal para pagamento do veículo */}
      <Dialog open={pagamentoVeiculo} onClose={finalizarPagamento}>
        <DialogTitle>Pagamento Estaci.one</DialogTitle>
        <DialogContent>
          <p>Valor há pagar</p>
          <input
            type="number"
            name="paraPagar"
            value={dinheiroHaPagar}
            disabled
          />
          <p>Valor Recebido</p>
          <input
            type="number"
            name="pago"
            onChange={e => setDinheiroPago(e.target.value)}
          />
          <p>Troco</p>
          <input
            type="number"
            name="troco"
            value={dinheiroPago - dinheiroHaPagar}
            disabled
          />
          <p>Forma de pagamento</p>
          <input
            type="radio"
            name="formapagamento"
            value="dinheiro"
            onClick={e => setFormaPagamento(0)}
          />{' '}
          Dinheiro <br />
          <input
            type="radio"
            name="formapagamento"
            value="cartaoDebito"
            onClick={e => setFormaPagamento(1)}
          />{' '}
          Cartão débito <br />
          <input
            type="radio"
            name="formapagamento"
            value="cartaoCredito"
            onClick={e => setFormaPagamento(2)}
          />{' '}
          Cartão crédito <br />
          <p>Recebeu o Pagamento</p>
          <input
            type="radio"
            name="recebeupagamento"
            value="true"
            onClick={e => setRecebeuPagamento(true)}
          />{' '}
          Sim <br />
          <input
            type="radio"
            name="recebeupagamento"
            value="false"
            onClick={naoRecebeuPagamento}
          />{' '}
          Não <br />
          <p hidden id="causa">
            <strong>Justifique-se</strong>
          </p>
          <textarea rows="5" cols="40" id="declarar" maxLength="300" hidden />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            id="pagar"
            type="button"
            onClick={finalizarPagamento}>
            Pagar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
