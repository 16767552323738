import { handleActions } from 'redux-actions';
import {
  FETCH_PERMISSIONS_FULFILLED,
  FETCH_PERMISSIONS_PENDING,
} from './actions';

export const initialState = {
  loading: false,
  permissions: [],
};

export default handleActions(
  {
    [FETCH_PERMISSIONS_PENDING]: state => ({
      ...state,
      loading: true,
    }),
    [FETCH_PERMISSIONS_FULFILLED]: (state, { payload }) => ({
      ...state,
      loading: false,
      permissions: payload,
    }),
  },
  initialState
);
