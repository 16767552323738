import { combineReducers } from 'redux';

import authReducer from './auth/reducer';
import userReducer from './user/reducer';
import errorReducer from './error/reducer';

const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  error: errorReducer,
});

export default rootReducer;
