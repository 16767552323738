import { Button } from '@material-ui/core';
import makeStyles from '@material-ui/styles/makeStyles';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ACCESS_TOKEN_KEY, logout } from '../../services/auth';
import AppBarEstacione from '../../components/AppBarEstacione';
import api from '../../services/api';
import './styles.css';

const lightColor = 'rgba(255, 255, 255, 0.7)';

const useStyles = makeStyles(theme => ({
  button: {
    borderColor: lightColor,
  },
  icon: {
    marginRight: theme.spacing(1),
  },
}));

export default function Relatorio() {
  const [caixaAbrir, setCaixaAbrir] = useState(0);
  const [caixaFechar, setCaixaFechar] = useState(0);
  const [listaCarros, setListaCarros] = useState([]);
  const history = useHistory();
  const [totalFaturado, setTotalFaturado] = useState(0);
  const [error, setError] = useState();

  useEffect(() => {
    const data = { eventoId: 2 };
    const userToken = localStorage.getItem(ACCESS_TOKEN_KEY);
    const headers = { Authorization: `Bearer ${userToken}` };

    async function fetchData() {
      try {
        const response = await api.post('relatorio', data, { headers });
        const {
          dinheiroEmCaixa,
          dinheiroAoAbrir,
          atualizado,
          valorTotal,
        } = response.data;
        setTotalFaturado(valorTotal);
        setCaixaAbrir(dinheiroAoAbrir);
        setCaixaFechar(dinheiroEmCaixa);
        setListaCarros(atualizado);
      } catch (err) {
        setError(err);
      }
    }
    fetchData();
  }, []);

  async function imprimirRelatorio() {
    try {
      window.print();
      // fazer chamada para a api para zerar o caixa, tirar o usuario do local storage, e voltar par o login
      let retorno = 0;
      try {
        const data = { eventoId: 3 };

        const userToken = localStorage.getItem(ACCESS_TOKEN_KEY);
        const headers = { Authorization: `Bearer ${userToken}` };

        const response = await api.put('caixa', data, { headers });
        retorno = response.status;
      } catch (err) {
        alert('Não foi possível zerar caixa.');
      }

      if (retorno === 200) {
        localStorage.clear();
        history.push('/');
      }
    } catch (err) {
      alert(
        'Não foi possível a impressão, Verifique se a impressora que vc escolheu está tudo certo com ela'
      );
    }
  }

  function handleClickLogout() {
    // setSnackbarShown(true);
    logout();
  }

  if (!error) {
    return (
      <div>
        <AppBarEstacione />

        <div>
          <ul>
            <li>
              <strong>Usuario:</strong>
              <p>Falta colocar</p>
              <strong>Dinheiro ganho:</strong>
              <p>
                {(caixaFechar - caixaAbrir).toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </p>
              <strong>Dinheiro em caixa: </strong>
              <p>
                {caixaFechar.toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </p>
              <strong>quantidade de veiculos: </strong>
              <p>{listaCarros.length}</p>
            </li>

            <table>
              <thead className="cabecalho">
                <tr>
                  <th>Placa</th>
                  <th>Forma de pagamento</th>
                  <th>Data de entrada</th>
                  <th>Horário de entrada</th>
                  <th>Data de saída</th>
                  <th>Horário de saída</th>
                  <th>Tempo permanecido</th>
                  <th>Dinheiro pago</th>
                </tr>
              </thead>
              <tbody>
                {listaCarros.map(veiculo => (
                  <tr key={veiculo.id}>
                    <td>{veiculo.placa}</td>
                    <td>{veiculo.formaQuePagou}</td>
                    <td>{veiculo.entradaD}</td>
                    <td>{veiculo.entradaH}</td>
                    <td>{veiculo.saidaD}</td>
                    <td>{veiculo.saidaH}</td>
                    <td>{veiculo.horaCalculada}</td>
                    <td>
                      {veiculo.valorPago.toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      })}
                    </td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <th>
                    Total recebido:
                    {totalFaturado.toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </th>
                </tr>
              </tfoot>
            </table>
          </ul>
        </div>
        <Button variant="contained" onClick={imprimirRelatorio}>
          Imprimir
        </Button>
      </div>
    );
  }
  return (
    <div>
      <h1>Relatório de Fechamento do caixa</h1>
    </div>
  );
}
