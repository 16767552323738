import EstacionamentoCadastro from 'pages/Estacionamento/Cadastro';
import EstacionamentoList from 'pages/Estacionamento/List';
import Login from 'pages/Login';
import Logout from 'pages/Logout';
import RedeEstacionamentoCadastro from 'pages/RedeEstacionamento/Cadastro';
import RedeEstacionamentoList from 'pages/RedeEstacionamento/List';
import Relatorio from 'pages/Relatorio';
import RelatorioTotal from 'pages/RelatorioTotal';
import Veiculo from 'pages/Veiculos';
import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import PrivateRoute from 'routes/PrivateRoute';

export default function RootRouter() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={Login} />
        <Route path="/login" component={Login} />
        <PrivateRoute path="/veiculos" component={Veiculo} />
        <PrivateRoute
          path="/rede-estacionamento/cadastro"
          component={RedeEstacionamentoCadastro}
        />
        <PrivateRoute
          path="/rede-estacionamento"
          component={RedeEstacionamentoList}
        />
        <PrivateRoute
          path="/estacionamento/cadastro"
          component={EstacionamentoCadastro}
        />
        <PrivateRoute path="/estacionamento" component={EstacionamentoList} />
        <PrivateRoute path="/relatorio" component={Relatorio} />
        <PrivateRoute path="/relatoriototal" component={RelatorioTotal} />
        <Route path="/logout" component={Logout} />
      </Switch>
    </BrowserRouter>
  );
}
